/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

// import Header from "./header"
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles.css"

const Layout = ({ children }) => {

  return (
    <>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>The Essential Blanket</title>
          <script defer type="text/javascript">
            {`
            ( function() {
            var youtube = document.querySelectorAll( ".youtube" );
            
            for (var i = 0; i < youtube.length; i++) {
            
            // var source = "https://img.youtube.com/vi/"+ youtube[i].dataset.embed +"/sddefault.jpg";
            
            // var image = new Image();
            // image.src = source;
            // image.addEventListener( "load", function() {
            // youtube[ i ].appendChild( image );
            // }( i ) );
            
            youtube[i].addEventListener( "click", function() {
            
            var iframe = document.createElement( "iframe" );
            
            iframe.setAttribute( "frameborder", "0" );
            iframe.setAttribute( "allowfullscreen", "" );
            iframe.setAttribute( "src", "https://www.youtube.com/embed/"+ this.dataset.embed +"?rel=0&showinfo=0&autoplay=1" );
            
            this.innerHTML = "";
            this.appendChild( iframe );
            } );
            };
            
            } )();
            `}
          </script>
        </Helmet>
        <main>{children}</main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
